<template>
    <div>
        <div v-if="sidebar" class="summary py-8" :class="width < 1199 ? 'px-4' : 'pl-8'">
            <div v-if="sidebar.lifecycle && source !== 'company'">
                <h3 class="mt-4 mb-2">{{ base.lifecycle[lang] }}</h3>
                <p class="mb-2">{{ sidebar.lifecycle[lang] }} </p>
            </div>
            <div v-if="sidebar.lifecycles && sidebar.lifecycles.length > 0 && source !== 'company'">
                <h3 class="mt-4 mb-2">{{ base.lifecycle[lang] }}</h3>
                <p class="mb-2" v-for="l in sidebar.lifecycles" :key="l._id"> {{ l[lang] }} </p>
            </div>
            <div class="pt_15" v-if="sidebar.areas && sidebar.areas.length > 0 && source !== 'company'">
                <h3 class="mt-4 mb-2">{{ base.areas[lang] }}</h3>
                <p class="mb-2" v-for="a in sidebar.areas" :key="a._id"> {{ a[lang] }} </p>
            </div>
            <div class="pt_15" v-if="source === 'company' && sidebar.invested">
                <h3 class="mt-4 mb-2">{{ base.invested[lang] }}</h3>
                <p class="my-2">{{ sidebar.invested.substring(0,4) }}</p>
            </div>
            <div class="pt_15" v-if="source === 'company' && sidebar.coworkers && sidebar.coworkers.length > 0">
                <h3 class="mt-4 mb-2">{{ base.investor[lang] }}</h3>
                <p class="my-2" v-for="c in sidebar.coworkers" :key="c._id">
                    <router-link :to="'/coworkers/' + c.slug">
                        <span v-if="lang === 'en'">{{ c.firstName }} {{ c.lastName }}</span>
                        <span v-if="lang === 'hun'">{{ c.lastName }} {{ c.firstName }}</span>
                    </router-link>
                </p>
            </div>
            <div class="pt_15" v-if="source === 'company' && sidebar.founders && sidebar.founders.length > 0">
                <h3 class="mt-4 mb-2">{{ base.founders[lang] }}</h3>
                <p class="my-2" v-for="f in sidebar.founders" :key="f._id">
                    <span v-if="lang === 'en'">{{ f.firstName }} {{ f.lastName }}</span>
                    <span v-if="lang === 'hun'">{{ f.lastName }} {{ f.firstName }}</span>
                </p>
            </div>
            <div class="pt_15" v-if="source === 'company' && sidebar.management && sidebar.management.length > 0">
                <h3 class="mt-4 mb-2">{{ base.management[lang] }}</h3>
                <p class="my-2" v-for="m in sidebar.management" :key="m._id">
                    <span v-if="lang === 'en'">{{ m.firstName }} {{ m.lastName }}</span>
                    <span v-if="lang === 'hun'">{{ m.lastName }} {{ m.firstName }}</span>
                </p>
            </div>
            <div class="pt_15" v-if="source === 'coworker' && sidebar.companies && sidebar.companies.length > 0">
                <h3 class="mt-4 mb-2">{{ base.portfolio[lang] }}</h3>
                <p class="my-2" v-for="p in sidebar.companies" :key="p._id">
                    <router-link :to="'/companies/' + p.slug">
                        {{ p.name }}
                    </router-link>
                </p>
            </div>
            <div class="contact-area pt_15" v-if="sidebar.linkedin || sidebar.twitter || sidebar.website || sidebar.calendly">
                <h3 class="mt-4 mb-2">{{ base.contact[lang] }}</h3>
                <a class="my-2" v-if="sidebar.linkedin" :href="sidebar.linkedin" target="_blank">
                    <img src="/assets/img/linkedin.svg" alt="Linkedin" height="20px"> <br>
                </a> 
                <a v-if="sidebar.twitter" :href="sidebar.twitter" target="_blank">                                
                    <img class="my-2" src="/assets/img/twitter.svg" alt="Twitter" height="20px"> <br>
                </a> 
                <a v-if="sidebar.website" :href="sidebar.website" target="_blank">
                    {{ sidebar.website }} <br>
                </a> 
                <div class="text-center" v-if="sidebar.calendly">
                    <a id="bookPitch" class="btn bg-green bookPitchBtn my-4"  href="javascript:void(0)" @click="openCalendly(coworker.calendly)">{{ base.bookPitch[lang] }}</a>
                </div>
            </div>
            <kinesis-container class="image-text-right-center" event="scroll" :duration="2000">
                <kinesis-element tag="div" :strength="150" axis="y"  :originY="0" type="translate" >
                    <div>
                        <img src="/assets/img/white_dotted_circle.svg" alt="">
                    </div>
                </kinesis-element>
            </kinesis-container>
        </div>
        <div v-else class="summary py-8" :class="width < 1199 ? 'px-4' : 'pl-8'">
            <div class=" ph-item">
                <div class="ph-col-12">
                    <div class="ph-row my-4">
                        <div class="ph-col-12 big mb-2"></div>
                        <div class="ph-col-8 "></div>
                        <div class="ph-col-8 "></div>
                    </div>
                    <div class="ph-row my-4">
                        <div class="ph-col-12 big mb-2"></div>
                        <div class="ph-col-8 "></div>
                        <div class="ph-col-8 "></div>
                    </div>
                    <div class="ph-row my-4">
                        <div class="ph-col-12 big mb-2"></div>
                        <div class="ph-col-8 "></div>
                        <div class="ph-col-8 "></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: ['sidebar', 'source'],
    data() {
        return {
            width: window.innerWidth
        };
    },
    computed: { 
        ...mapState(['lang', 'base'])
    },
    mounted() {
        window.addEventListener('resize', () => {         
            this.width = window.innerWidth       
        });
    }
}
</script>